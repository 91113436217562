// NAVIGATION

$('.next').on('mouseover', function() {
    $('header').addClass('move-left');
    $('body').addClass('forward');

});
$('.next').on('mouseout', function() {
    $('header').removeClass('move-left');
    $('body').removeClass('forward');


});
$('.prev').on('mouseover', function() {
    $('header').addClass('move-right');
    $('body').addClass('backward');

});
$('.prev').on('mouseout', function() {
    $('header').removeClass('move-right');
    $('body').removeClass('backward');

});


// OVERVIEW

$(".overview div").click(function() {
    window.location = $(this).find("a").attr("href");
    return false;
});


// VIDEO
const videoElement = document.getElementById('video');

if (document.querySelector('video').playing) {
    $('header.cover').addClass('play');
    $('header.cover').addClass('wtf');
    $('.video-container').addClass('show');
}

var video = $('#video');

if (!videoElement.paused) {
    $('header.cover').addClass('play');
    $('header.cover').addClass('wtf');
}



// Controlls
// const btnBackward = document.querySelector('.btn-backward');
// const btnExpand = document.querySelector('.btn-expand');
// const btnMute = document.querySelector('.btn-mute');
// const btnMuteIcon = btnMute.querySelector('.fa');
const btnPlay = document.querySelector('.btn-play');
const btnPlayIcon = btnPlay.querySelector('.fa');
// const btnForward = document.querySelector('.btn-forward');
// const btnReset = document.querySelector('.btn-reset');
// const btnStop = document.querySelector('.btn-stop');
const progressBar = document.querySelector('.progress-bar');
const progressBarFill = document.querySelector('.progress-bar-fill');

const title = document.getElementById('title');
// const playMobile = document.getElementById('playMobile');

const cover = document.querySelector('.cover');
const container = document.querySelector('.video-container');



// // Toggle full-screen mode
const expandVideo = () => {
  if (videoElement.requestFullscreen) {
    videoElement.requestFullscreen();
  } else if (videoElement.mozRequestFullScreen) {
    // Version for Firefox
    videoElement.mozRequestFullScreen();
  } else if (videoElement.webkitRequestFullscreen) {
    // Version for Chrome and Safari
    videoElement.webkitRequestFullscreen();
  }
}

// Move the video backward for 5 seconds
const moveBackward = () => {
  videoElement.currentTime -= 5;
}

// Move the video forward for 5 seconds
const moveForward = () => {
  videoElement.currentTime += 5;
}

// Mute the video
const muteVideo = () => {
  if (videoElement.muted) {
    videoElement.muted = false;

    btnMuteIcon.classList.remove('fa-volume-up');
    btnMuteIcon.classList.add('fa-volume-off');
  } else {
    videoElement.muted = true;

    btnMuteIcon.classList.remove('fa-volume-off');
    btnMuteIcon.classList.add('fa-volume-up');
  }
}

// Play / Pause the video
const playPauseVideo = () => {
  if (videoElement.paused) {
    videoElement.play();

    btnPlayIcon.classList.remove('fa-play');
    btnPlayIcon.classList.add('fa-pause');
     container.classList.add('show');
    cover.classList.add('play');
  } else {
    videoElement.pause();

    btnPlayIcon.classList.remove('fa-pause');
    btnPlayIcon.classList.add('fa-play');
    cover.classList.remove('play');

  }
}

videoElement.onclick = () => {
  if (videoElement.paused) {
    videoElement.play();
    container.classList.add('show');
    cover.classList.add('play');
    btnPlayIcon.classList.remove('fa-play');
    btnPlayIcon.classList.add('fa-pause');
  } else {
    videoElement.pause();
    btnPlayIcon.classList.remove('fa-pause');
    btnPlayIcon.classList.add('fa-play');
    cover.classList.remove('play');
  }
}
var playMobile = document.getElementsByClassName("playMobile")
console.log(playMobile)
for (var i = 0; i < playMobile.length; i++) {
  playMobile[i].onclick = () => {
  if (videoElement.paused) {
    videoElement.play();
    container.classList.add('show');
    cover.classList.add('play');
    btnPlayIcon.classList.remove('fa-play');
    btnPlayIcon.classList.add('fa-pause');
  } else {
    videoElement.pause();
    btnPlayIcon.classList.remove('fa-pause');
    btnPlayIcon.classList.add('fa-play');
    cover.classList.remove('play');
  }
}

}
// playMobile.onclick = () => {
//   if (videoElement.paused) {
//     videoElement.play();
//     container.classList.add('show');
//     cover.classList.add('play');
//     btnPlayIcon.classList.remove('fa-play');
//     btnPlayIcon.classList.add('fa-pause');
//   } else {
//     videoElement.pause();
//     btnPlayIcon.classList.remove('fa-pause');
//     btnPlayIcon.classList.add('fa-play');
//     cover.classList.remove('play');
//   }
// }
title.onclick = () => {
  if (videoElement.paused) {
    videoElement.play();
    container.classList.add('show');
    cover.classList.add('play');
    btnPlayIcon.classList.remove('fa-play');
    btnPlayIcon.classList.add('fa-pause');
  } else {
    videoElement.pause();
    btnPlayIcon.classList.remove('fa-pause');
    btnPlayIcon.classList.add('fa-play');
    cover.classList.remove('play');
  }
}

// Restart the video
const restartVideo = () => {
  videoElement.currentTime = 0;

  btnPlay.removeAttribute('hidden');
  btnReset.setAttribute('hidden', 'true');
}

// Stop the video
const stopVideo = () => {
  videoElement.pause();
  videoElement.currentTime = 0;
  btnPlayIcon.classList.remove('fa-pause');
  btnPlayIcon.classList.add('fa-play');
}

// Update progress bar as the video plays
const updateProgress = () => {
  // Calculate current progress
  let value = (100 / videoElement.duration) * videoElement.currentTime;

  // Update the slider value
  progressBarFill.style.width = value + '%';
}

progressBar.onclick = (e) => {
  // alert(videoElement.currentTime)
  // videoElement.currentTime = 6;

  let pos = (e.pageX  - (progressBar.offsetLeft + progressBar.offsetParent.offsetLeft)) / progressBar.offsetWidth;
   // console.log(pos * videoElement.duration)
   // console.log(videoElement.duration)
   
   videoElement.currentTime = pos * videoElement.duration;
}


// Event listeners
// btnBackward.addEventListener('click', moveBackward, false);
// btnExpand.addEventListener('click', expandVideo, false);
// btnMute.addEventListener('click', muteVideo, false);
btnPlay.addEventListener('click', playPauseVideo, false);
// btnForward.addEventListener('click', moveForward, false);
// btnReset.addEventListener('click', restartVideo, false);
// btnStop.addEventListener('click', stopVideo, false);
videoElement.addEventListener('ended', () => {
  btnPlay.setAttribute('hidden', 'true');
  btnReset.removeAttribute('hidden');
}, false);
videoElement.addEventListener('timeupdate', updateProgress, false); 




$(document).scroll(function() {
    if ($(window).scrollTop() === 0) {
        $("header#header h1 span").removeClass('visible');
        if ($('.video-container').hasClass("show")) {
            document.getElementById('video').play();
            $('header.cover').addClass('play');


        }

    } else {
        $("header#header h1 span").addClass('visible');
        document.getElementById('video').pause();
        $('.project-title').addClass('hide');



    }
});

$(document).ready(function() {
    $(".loader").addClass('hide');


});
